<template>
<section class="px-3">
    <n-alert class="rounded-lg" type="warning" size="small">
        <template #header>
            <n-collapse arrow-placement="right">
                <n-collapse-item>
                    <template #header>
                        <div class="text-[10px] leading-4" v-html="parsed[0]"></div>
                    </template>
                    <div
                        class="notes text-[10px] leading-4"
                        v-html="parsed.slice(1).join('')">
                    </div>
                </n-collapse-item>
            </n-collapse>
        </template>
    </n-alert>

    <div v-if="!init" class="flex h-full justify-center items-center py-20">
        <n-spin size="small" />
    </div>
    <div v-else>
      <super-table
          showSearch
          :filters="filters"
          :actions="actions"
          :loading="loadings.table"
          :refFilters="refFilters"
          :pager="marketplace.sellPager"
          :filtersChanged="filtersChanged"
          :columns="marketplace.sellColumns"
          :records="marketplace.sellRecords"
          columnsStorageName="__columns_marketplace-sell"
          type="marketplace.sell"
          @getData="getData"
          @sortColumn="sortColumn"
          @applyFilters="changeFilter"
          @doSomething="doSomething"
          @reset-filters="resetFilters">
          <template #tableTitle>
              <div class="text-sm text-left mb-2">
                    {{ marketplace.localization?.['marketplace/buy/grid/title'] }}:
                    <span class="text-main font-semibold text-md">{{ marketplace.sellTotal }}</span>
                </div>
          </template>
      </super-table>
  </div>
</section>

<bot-review-sell
    v-if="marketplace.localization"
    :show="showModal"
    @getData="getData" />
</template>

<script>
// general
import general from './general';

// naive-ui
import {
    NSpin,
    NCard,
    NAlert,
    NModal,
    NDivider,
    NSkeleton,
    NCollapse,
    NCollapseItem } from 'naive-ui';

// components
import FilterCoins from '@components/filters/coins.vue';
import BotReviewSell from '@components/bot-review-sell/mobile.vue';
import SuperTable from '@components/super-table/mobile.vue';
import GridSettings from '@components/base/grid-settings.vue';
import ButtonGroup from '@components/filters/button-group.vue';
import FilterExchanges from '@components/filters/exchanges.vue';
import FilterChangedDate from '@components/filters/changedDate.vue';

export default {
    name: 'marketplace-sell-mobile',
    components: {
        NSpin,
        NCard,
        NModal,
        NAlert,
        NDivider,
        NCollapse,
        NSkeleton,
        SuperTable,
        ButtonGroup,
        FilterCoins,
        GridSettings,
        BotReviewSell,
        NCollapseItem,
        FilterExchanges,
        FilterChangedDate,
    },
    setup(props, context) {
        
        return {
            ...general(...arguments),
        };
    },
};
</script>